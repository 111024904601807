import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSignOutUserMutation } from '../api/authSlice';
import { useAppSelector } from '../hooks/reduxStoreHooks';
import useGetRoleTranslationString from '../hooks/useGetRoleTranslationString';
import {
  PATH_APP_ACCOUNT_SETTINGS,
  PATH_APP_UPDATE_PASSWORD,
} from '../routes/paths';
import MenuPopover from './MenuPopover';

export default function AccountPopover() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signOut] = useSignOutUserMutation();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const rootCompanyId = useAppSelector((state) => state.user.rootCompany?.id);
  const baseUserInfo = useAppSelector((state) => state.user.baseUserInfo);
  const userAccount = useAppSelector((state) => state.user.userAccountData);

  const roleTranslation = useGetRoleTranslationString(userAccount?.roleId ?? 0);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClick = (path: string) => {
    navigate(path);
    setOpen(null);
  };

  const MENU_OPTIONS = [
    {
      label: t('nav.manageAccount'),
      linkTo: PATH_APP_ACCOUNT_SETTINGS.root,
      onClick: handleClick,
    },
    {
      label: t('nav.changePassword'),
      linkTo: PATH_APP_UPDATE_PASSWORD.root,
      onClick: handleClick,
    },
    // {
    //   label: t('nav.help'),
    //   linkTo: PATH_APP_HELP.root,
    //   onClick: handleClick,
    // },
  ];

  const handleLogout = async () => {
    signOut(undefined);
  };

  if (baseUserInfo) {
    return (
      <>
        <IconButton
          onClick={handleOpen}
          sx={{
            ...(open && { bgcolor: 'action.selected' }),
          }}
        >
          <SettingsIcon />
        </IconButton>

        <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose}>
          <Stack sx={{ my: 1.5, px: 2.5 }}>
            <Typography
              noWrap
              sx={{ fontSize: '14px', color: 'text.secondary' }}
            >
              {baseUserInfo?.firstName} {baseUserInfo?.lastName}
            </Typography>

            <Typography
              noWrap
              sx={{ fontSize: '12px', color: 'text.secondary' }}
            >
              {roleTranslation}
            </Typography>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          {rootCompanyId && (
            <Stack sx={{ p: 1 }}>
              {MENU_OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  onClick={() => option.onClick(option.linkTo)}
                >
                  {t(`${option.label}` as unknown as TemplateStringsArray)}
                </MenuItem>
              ))}
            </Stack>
          )}

          <Divider sx={{ borderStyle: 'dashed' }} />

          <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
            <LogoutIcon sx={{ mr: 2 }} color="action" />
            {t('login.logout')}
          </MenuItem>
        </MenuPopover>
      </>
    );
  }
  return <></>;
}
