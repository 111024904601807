import { COMPANY_LEVEL_ID } from '../constants';
import { useAppSelector } from './reduxStoreHooks';

export default function useIsUserFromAdminOrDevCompany() {
  const rootCompany = useAppSelector((state) => state.user.rootCompany);

  return (
    rootCompany?.companyLevelId === COMPANY_LEVEL_ID.DEV_COMPANY ||
    rootCompany?.companyLevelId === COMPANY_LEVEL_ID.ADMIN_COMPANY
  );
}
