import { lazy } from 'react';

import { Loadable } from '../components/Loadable';

//Auth
export const LoginPage = Loadable(
  lazy(() => import('../pages/LoginPage')),
  { showLoadingScreen: true }
);

// Plots
export const PlotsPage = Loadable(lazy(() => import('../pages/PlotsPage')));
export const AddPlots = Loadable(
  lazy(() => import('../pages/PlotsPage/forms/index'))
);

// Legality
export const LegalityPage = Loadable(
  lazy(() => import('../pages/LegalityPage'))
);
export const CreateLegality = Loadable(
  lazy(() => import('../pages/LegalityPage/CreateLegality'))
);
export const CompanyDocuments = Loadable(
  lazy(() => import('../pages/LegalityPage/CompanyDocuments'))
);
export const PlotDocuments = Loadable(
  lazy(() => import('../pages/LegalityPage/PlotDocuments'))
);

// Company management
export const CompanyManagementPage = Loadable(
  lazy(() => import('../pages/CompanyManagementPage'))
);
export const CreateCompany = Loadable(
  lazy(() => import('../pages/CompanyManagementPage/form/CreateCompany'))
);
export const EditCompany = Loadable(
  lazy(() => import('../pages/CompanyManagementPage/form/EditCompany'))
);

// Users management
export const UsersManagementPage = Loadable(
  lazy(() => import('../pages/UsersManagementPage'))
);
export const CreateUser = Loadable(
  lazy(() => import('../pages/UsersManagementPage/form/CreateUser'))
);
export const EditUser = Loadable(
  lazy(() => import('../pages/UsersManagementPage/form/EditUser'))
);

// GeoJson Serbia
export const ImportCadastralDataSerbia = Loadable(
  lazy(() => import('../pages/ImportCadastralDataSerbia'))
);

// Counters
export const CountersPage = Loadable(
  lazy(() => import('../pages/CountersPage'))
);

// Rest
export const AccountSettingsPage = Loadable(
  lazy(() => import('../pages/AccountSettingsPage'))
);
export const UpdatePasswordPage = Loadable(
  lazy(() => import('../pages/UpdatePasswordPage'))
);
export const HelpPage = Loadable(lazy(() => import('../pages/HelpPage')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(
  lazy(() => import('../pages/HomePage')),
  { showLoadingScreen: true }
);
export const SelectedCompanyPage = Loadable(
  lazy(() => import('../pages/SelectCompanyPage')),
  { showLoadingScreen: true }
);
