import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Stack, Tooltip } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/reduxStoreHooks';
import useGetNavItems from '../../../hooks/useGetNavItems';
import { PATH_APP_SELECT_COMPANY } from '../../../routes/paths';
import AccountPopover from '../../AccountPopover';
import CustomContainer from '../../CustomContainer';
import HarvestYearHeading from '../../HarvestYearHeading';
import Logo from '../../Logo';
import RootCompanyHeading from '../../RootCompanyHeading';
import SupplyChainHeading from '../../SupplyChainHeading';
import NavItemButton from '../NavItemButton';

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  has_root_company?: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, has_root_company }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: has_root_company ? drawerWidth : 0,
        width: `calc(100% - ${has_root_company ? drawerWidth : 0}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));
export default function DesktopNav() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const NavItems = useGetNavItems();

  const [open, setOpen] = useState(true);
  const rootCompanyId = useAppSelector((state) => state.user.rootCompany?.id);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: `100%`,
      }}
    >
      <AppBar
        position="fixed"
        open={open}
        color="inherit"
        has_root_company={rootCompanyId}
      >
        <Toolbar>
          {rootCompanyId && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={[
                {
                  marginRight: 4,
                },
                open && { display: 'none' },
              ]}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Stack
            sx={{ width: '100%' }}
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Stack direction="row" alignItems="center" gap={2}>
              {!rootCompanyId && <Logo disabledLink />}
              {rootCompanyId && (
                <>
                  <Logo />
                  <RootCompanyHeading />
                  <SupplyChainHeading />
                  <HarvestYearHeading />
                </>
              )}
            </Stack>

            <Stack direction="row" gap={1} alignItems="center">
              {rootCompanyId && (
                <Button
                  variant="outlined"
                  onClick={() => navigate(PATH_APP_SELECT_COMPANY.root)}
                >
                  {t('general.selectCompany')}
                </Button>
              )}
              <AccountPopover />
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      {rootCompanyId && (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <Divider />
          <List sx={{ px: 1, mt: 1 }}>
            {NavItems.map((navItem) => (
              <Box key={navItem.label}>
                <Tooltip
                  arrow
                  title={navItem.label}
                  placement="right"
                  disableHoverListener={open}
                >
                  <span>
                    <NavItemButton navItem={navItem} open={open} />
                  </span>
                </Tooltip>
                {navItem.addDividerAfter && <Divider sx={{ my: 2 }} />}
              </Box>
            ))}
          </List>
        </Drawer>
      )}
      <Box
        component="main"
        data-testid="DesktopNav"
        sx={{
          mt: 8,
          mb: 5,
          width: '100%',
          overflow: 'auto',
        }}
      >
        <CustomContainer>
          <Outlet />
        </CustomContainer>
      </Box>
    </Box>
  );
}
