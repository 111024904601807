import BusinessIcon from '@mui/icons-material/Business';
import LayersIcon from '@mui/icons-material/Layers';
import PeopleIcon from '@mui/icons-material/People';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SatelliteIcon from '@mui/icons-material/Satellite';
import TaskIcon from '@mui/icons-material/Task';
import { useTranslation } from 'react-i18next';

import { PermissionTypes, ResourceNameEnum } from '../constants';
import {
  PATH_APP_COUNTERS,
  PATH_APP_GEO_JSON_SERBIA,
  PATH_APP_LEGALITY,
  PATH_APP_PLOTS,
  PATH_APP_USER_MANAGEMENT,
} from '../routes/paths';
import { NavItem } from '../types/app';
import { useAppSelector } from './reduxStoreHooks';
import useGetTypeOfPermissionForResource from './useGetTypeOfPermissionForResource';
import useIsUserFromAdminOrDevCompany from './useIsUserFromAdminOrDevCompany';

export default function useGetNavItems() {
  const { t } = useTranslation();
  const rootCompanyId = useAppSelector((state) => state.user.rootCompany?.id);

  const isAdmin = useIsUserFromAdminOrDevCompany();

  const noAccessPermissionForPlots = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.PLOT,
    permissionType: PermissionTypes.NO_ACCESS,
  });

  const noAccessPermissionForCompanies = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.COMPANY,
    permissionType: PermissionTypes.NO_ACCESS,
  });

  const noAccessPermissionForUsers = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.USER,
    permissionType: PermissionTypes.NO_ACCESS,
  });

  const noAccessPermissionForLegality = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.LEGALITY,
    permissionType: PermissionTypes.NO_ACCESS,
  });

  if (!rootCompanyId) {
    return [];
  }

  const NavItems: NavItem[] = [];

  if (!noAccessPermissionForPlots) {
    NavItems.push({
      label: t('nav.plotsOfLand'),
      icon: SatelliteIcon,
      to: PATH_APP_PLOTS.root,
      addDividerAfter: noAccessPermissionForLegality, // will show divider if there is no legality nav item
    });
  }

  if (!noAccessPermissionForLegality) {
    NavItems.push({
      label: t('nav.legality'),
      icon: TaskIcon,
      to: PATH_APP_LEGALITY.root,
      addDividerAfter: true,
    });
  }

  if (!noAccessPermissionForCompanies) {
    NavItems.push({
      label: t('nav.companies'),
      icon: BusinessIcon,
      to: PATH_APP_USER_MANAGEMENT.companiesOverview,
    });
  }

  if (!noAccessPermissionForUsers) {
    NavItems.push({
      label: t('nav.users'),
      icon: PeopleIcon,
      to: PATH_APP_USER_MANAGEMENT.usersOverview,
      addDividerAfter: true,
    });
  }

  if (isAdmin) {
    NavItems.push(
      {
        label: t('nav.importCadastralDataSerbia'),
        icon: LayersIcon,
        to: PATH_APP_GEO_JSON_SERBIA.root,
      },
      {
        label: t('nav.counters'),
        icon: QueryStatsIcon,
        to: PATH_APP_COUNTERS.root,
        addDividerAfter: true,
      }
    );
  }

  return NavItems;
}
