import { alpha, Box, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import packageJson from '../../package.json';
import FooterLogo from '../assets/FooterLogo.png';
import useResponsive from '../hooks/useResponsive';
import palette from '../theme/palette';

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const isTablet = useResponsive('down', 'md');

  const imageHeight = 55;
  const aspectRation = 2.52;
  const imageWidth = imageHeight * aspectRation;
  const footerHeight = isTablet ? 140 : imageHeight + 24;

  const xsFontSize = 12;
  const smFontSize = xsFontSize * 1.2;

  return (
    <Stack
      component="footer"
      sx={{
        height: footerHeight,
        zIndex: 1300,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: ({ palette }) => palette.secondary.main,
        p: 1.5,
        boxShadow: `0 -1px 8px ${alpha(palette.light.secondary.main, 0.08)}`,
      }}
      direction={isTablet ? 'column' : 'row'}
      alignItems="center"
      justifyContent="center"
    >
      <Stack>
        <Stack>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              letterSpacing: 0.5,
              fontSize: { xs: xsFontSize, sm: smFontSize },
              color: ({ palette }) => palette.grey[200],
            }}
          >
            {t('copyRight.main', { year: currentYear })}
            {`${t('general.version')} ${packageJson.version}`}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" gap={1}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:support@crop-insights.com"
            variant="caption"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: xsFontSize, sm: smFontSize },
            }}
          >
            {t('copyRight.contact')}
          </Link>
          <Typography sx={{ color: ({ palette }) => palette.grey[200] }}>
            |
          </Typography>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.donausoja.org/sr/imprint/"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: xsFontSize, sm: smFontSize },
            }}
          >
            {t('copyRight.imprint')}
          </Link>
        </Stack>
      </Stack>

      <Box
        component="img"
        sx={{
          position: isTablet ? 'relative' : 'absolute',
          right: isTablet ? 0 : 16,
          height: imageHeight,
          width: imageWidth,
        }}
        src={FooterLogo}
        alt="companyFooterLogo"
      />
    </Stack>
  );
}
