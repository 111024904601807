function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const HOME_ROOT = '/home';
export const AUTH_ROOT = '/auth';
export const APP_ROOT = '/app';

export const PATH_HOME = {
  root: HOME_ROOT,
};

export const PATH_AUTH = {
  root: AUTH_ROOT,
  login: path(AUTH_ROOT, '/login'),
};

const PATH_APP = {
  root: APP_ROOT,
  plots: path(APP_ROOT, '/plots'),
  legality: path(APP_ROOT, '/legality'),
  accounts: path(APP_ROOT, '/accounts'),
  accountSettings: path(APP_ROOT, '/account-settings'),
  updatePassword: path(APP_ROOT, '/update-password'),
  help: path(APP_ROOT, '/help'),
  selectCompany: path(APP_ROOT, '/select-company'),
  geoJsonSerbia: path(APP_ROOT, '/geo-json-serbia'),
  counters: path(APP_ROOT, '/counters'),
};

export const PATH_APP_PLOTS = {
  root: PATH_APP.plots,
};
export const PATH_APP_LEGALITY = {
  root: PATH_APP.legality,
  create: path(PATH_APP.legality, '/create'),
  companyDocuments: path(PATH_APP.legality, '/company-documents'),
  plotDocuments: path(PATH_APP.legality, '/plot-documents'),
};
export const PATH_APP_ACCOUNT_SETTINGS = {
  root: PATH_APP.accountSettings,
};
export const PATH_APP_UPDATE_PASSWORD = {
  root: PATH_APP.updatePassword,
};
export const PATH_APP_HELP = {
  root: PATH_APP.help,
};
export const PATH_APP_SELECT_COMPANY = {
  root: PATH_APP.selectCompany,
};

export const PATH_APP_USER_MANAGEMENT = {
  // ACCOUNTS
  //      companies
  companiesOverview: path(PATH_APP.accounts, '/companies'),
  newCompany: path(PATH_APP.accounts, '/companies/new'),
  editCompany: path(PATH_APP.accounts, '/companies'),
  //      users
  usersOverview: path(PATH_APP.accounts, '/users'),
  newUser: path(PATH_APP.accounts, '/users/new'),
  editUser: path(PATH_APP.accounts, '/users'),
};

export const PATH_APP_PLOTS_MANAGEMENT = {
  newPlot: path(PATH_APP.plots, '/new'),
  exportPlotDoc: path(PATH_APP.plots, '/export-plot-doc'),
};

export const PATH_APP_GEO_JSON_SERBIA = {
  root: PATH_APP.geoJsonSerbia,
};

export const PATH_APP_COUNTERS = {
  root: PATH_APP.counters,
};
